import axios from 'axios';
import config from '@/../config';

export const actions = {
    async recoverState({ commit, dispatch }, contact_hash) {
        return await axios({
            method: 'get',
            url: `${config.apiUrl}/state?contact_hash=${contact_hash}`,
            headers: {
                'Authorization': `Bearer ${config.apiToken}`
            },
        }).then(async (response) => {
            let data = response.data.data;
            commit('setFormData', {
                name: data.name,
                phone: data.phone,
                branch: data.branch,
                branchName: await dispatch('fetchOfficeName', data.office_id),
                office_id: data.office_id,
                date: data.consultation_date,
                consultations_date_limit: data.consultation_date,
                timeStart: data.timeStart,
                timeEnd: data.timeEnd,
                link: data.link
            });
            return {
                data: data, 
                status: response.status
            }
        });
    },

    async setConsData({ commit, dispatch }, data) {
        commit('setActiveCons', {
            'cityId': data.cityId,
            'officeId': data.officeId,
            'branchName': await dispatch('fetchOfficeName', data.officeId),
            'dateLocal': data.dateLocal,
            'beginTimeLocal': data.beginTimeLocal,
            'endTimeLocal': data.endTimeLocal,
            'timeStart': data.beginTimeLocal,
            'duration': data.duration,
            'lastDate': data.beginsAtUtc
        });
    },

    async fetchOfficeName({ commit, dispatch }, officeId) {
        const branches = await dispatch('fetchOffices');
        let name = '';
        branches.data.forEach(function (item) {
            if (officeId === item.office_id) {
                name = item.address_sms || item.address;
                commit('setBranch', item);
            }
        })
        return name;
    },

    formRecord({ commit }, payload) {
        let data = {
            name: payload.name,
            phone: payload.phone,
            city_id: payload.branch.value.city_id,
            office_id: payload.branch.value.office_id,
            date: payload.date,
            slot: {
                timeStart: payload.time.timeStart,
                timeEnd: payload.time.timeEnd,
                duration: payload.time.duration
            },
            smart_token: payload.smart_token,
            utm_source: payload.utm_source,
            utm_medium: payload.utm_medium,
            utm_campaign: payload.utm_campaign,
            utm_content: payload.utm_content,
            utm_term: payload.utm_term,
            attributio: payload.attributio,
            x_user_id: payload.x_user_id,
            agent_ref: payload.agent_ref
        };
        return axios({
            method: 'post',
            url: `${config.apiUrl}/form`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${config.apiToken}`
            },
            data: JSON.stringify(data)
        }).then((response) => {
            sessionStorage.contact_hash = response.data.hash;
            commit('setFormData', {
                'name': payload.name,
                'phone': payload.phone,
                'branchName': payload.branch.value.name,
                'office_id': payload.branch.value.office_id,
                'date': payload.date,
                'consultations_date_limit': payload.branch.consultations_date_limit,
                'timeStart': payload.time.timeStart,
                'timeEnd': payload.time.timeEnd
              });
              localStorage.removeItem('utm_source');
              localStorage.removeItem('utm_medium');
              localStorage.removeItem('utm_campaign');
              localStorage.removeItem('utm_content');
              localStorage.removeItem('utm_term');
            return {
                data: response.data, 
                status: response.status
            }
        });
    },

    confirmRecord(_, payload) {
        return axios({
            method: 'post',
            url: `${config.apiUrl}/confirm`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${config.apiToken}`
            },
            data: JSON.stringify({
                phone: payload.phone,
                code: payload.code,
                is_editing: payload.is_editing
            }),
        }).then((response) => {
            return {
                data: response.data, 
                status: response.status
            }
        }).catch((error) => {
            return {
                data: error.response.data, 
                status: error.response.status
            }
        });
    },

    takeRecordTimeSlot({ commit }, payload) {
        return axios({
            method: 'post',
            url: `${config.apiUrl}/form/slot`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${config.apiToken}`
            },
            data: JSON.stringify({
                slot: payload.slot,
                phone: payload.phone,
                date: payload.date,
                is_editing: payload.is_editing
            }),
        }).then((response) => {
            sessionStorage.contact_hash = response.data.hash;
            commit('setFormData', {
                'date': this.date,
                'timeStart': this.time.timeStart,
                'timeEnd': this.time.timeEnd,
                'consultations_date_limit': this.branch.consultations_date_limit
            });
            commit('setLink', response.data.link);
            return {
                data: response.data, 
                status: response.status
            }
        });
    },

    fetchTimeSlots({ commit }, payload) {
        let groups = {
            default: { key: 0, name: '' },
            morning: { key: 1, name: 'Утро' },
            daytime: { key: 2, name: 'День' },
            evening: { key: 3, name: 'Вечер' }
        }
        return axios({
            method: 'get',
            url: `${config.apiUrl}/slot?office_id=${payload.office_id}&date=${payload.date}`,
            headers: {
                'Authorization': `Bearer ${config.apiToken}`
            },
        }).then((response) => {
            const data = [];
            response?.data.data?.forEach(function (item) {
                let group = groups.default;
                if (item.hour < 12 && item.minute >= 0) {
                    group = groups.morning;
                } else if (item.hour >= 12 && item.hour < 18 && item.minute >= 0) {
                    group = groups.daytime;
                } else if (item.hour >= 18 && item.minute >= 0) {
                    group = groups.evening;
                }
                data.push({
                    group: group,
                    name: item.name,
                    timeStart: item.timeStart,
                    timeEnd: item.timeEnd,
                    duration: item.duration,
                    available: item.available
                });
            });
            let groupResult = Object.groupBy(data, ({ group }) => group.key);
            commit('setTimeList', groupResult);

            return {
                data: groupResult, 
                status: response.status
            }
        });
    },

    fetchOffices({ commit }) {
        return axios({
            method: 'get',
            url: `${config.apiUrl}/office`,
            headers: {
                'Authorization': `Bearer ${config.apiToken}`
            },
        }).then((response) => {
            const data = [];
            response.data.forEach(function (item) {
                data.push({
                    label: item.address_sms || item.address,
                    consultations_date_limit: item.consultations_date_limit,
                    value: {
                        name: item.name,
                        office_id: item.office_id,
                        city_id: item.city_id
                    }
                });
            });
            commit('setBranchList', data);

            return {
                data: response.data, 
                status: response.status
            }
        });
    },

    getNewCode(_, { phone }) {
        return axios({
            method: 'post',
            url: `${config.apiUrl}/code`,
            data: { phone },
            headers: {
                'Authorization': `Bearer ${config.apiToken}`
            },
        }).then((response) => {
            return {
                data: response.data, 
                status: response.status
            }
        });
    },

    processPersonalLink({ commit, dispatch }, query) {
        return axios({
            method: 'get',
            url: `${config.apiUrl}/personal?${query}`,
            headers: {
                'Authorization': `Bearer ${config.apiToken}`
            },
        }).then((response) => {
            if (response.status === 200 && response?.data?.success) {
                let personalData = response.data.data;
                commit('setPhone', personalData.phone);
                commit('setName', personalData.name);
                switch (response.data.code) {
                    case 'CONS_NOT_FOUND':
                        commit('setPersonal', {
                            'x_user_id': personalData.xUserId ?? '',
                            'phone': personalData.phone,
                            'utm_campaign': personalData.utm.utm_campaign,
                            'utm_content': personalData.utm.utm_content,
                            'utm_source': personalData.utm.utm_source
                        });
                        break;
                    case 'ACTIVE_CONS_FOUND':
                        dispatch('setConsData', personalData.consultation);
                        break;
                }
            }
            return {
                data: response.data, 
                status: response.status
            }
        }).catch(() => ({ status: 422 }));
    },
    
    cancelRecord() {
        return axios({
            method: 'post',
            url: `${config.apiUrl}/cancel`,
            data: { 'contact_hash': sessionStorage.contact_hash },
            headers: {
                'Authorization': `Bearer ${config.apiToken}`
            },
        }).then((response) => ({status: response.status}));
    }
}
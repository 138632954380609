export const getters = {
    getTimeList(state) {
        return state.times;
    },
    getBranchList(state) {
        return state.branches;
    },
    getBranch(state) {
        return state.branch;
    },
    getContactHash(state) {
        return state.contact_hash;
    },
    getContactHashWithSession(state) {
        return sessionStorage.contact_hash || state.contact_hash || '';
    },
    getActiveCons(state) {
        return state.activeCons;
    },
    getPersonal(state) {
        return state.personal;
    },
    getLink(state) {
        return state.link;
    },
    getPhone(state) {
        return state.phone;
    },
    getName(state) {
        return state.name;
    },
    getBranchName(state) {
        return state.branchName;
    },
    getDate(state) {
        return state.date;
    },
    getTime(state) {
        return state.time;
    },
    getOfficeId(state) {
        return state.office_id;
    },
    getConsultationsDateLimit(state) {
        return state.consultations_date_limit;
    },
    getTimeStart(state) {
        return state.timeStart;
    },
    getTimeEnd(state) {
        return state.timeEnd;
    }
}
import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';

import { plugin, defaultConfig } from '@formkit/vue';
import { createMultiStepPlugin } from '@formkit/addons';
import { SmartCaptchaPlugin } from 'vue3-smart-captcha';
import vSelect from 'vue-select';
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'

import 'bootstrap/dist/css/bootstrap.css';
import '@formkit/themes/genesis'
import 'vue-select/dist/vue-select.css';
//import '@formkit/addons/css/multistep';

const app = createApp(App)
app.use(plugin, defaultConfig({
    theme: 'genesis',
    plugins: [
        createMultiStepPlugin()
    ],
}));
app.use(router);
app.use(SmartCaptchaPlugin);
app.use(store);
app.use(mdiVue, { icons: mdijs })
app.mount('#app');
app.component('v-select', vSelect);

import 'bootstrap/dist/js/bootstrap.js';

export const mutations = {
    setTimeList(state, times) {
        state.times = times;
    },
    setBranchList(state, branches) {
        state.branches = branches;
    },
    setContactHash(state, value) {
        state.contact_hash = value;
    },
    setActiveCons(state, data) {
        state.activeCons = data;
    },
    setPersonal(state, data) {
        state.personal = data;
    },
    setBranch(state, branch) {
        state.branch = branch;
    },
    setPhone(state, phone) {
        state.phone = phone;
    },
    setName(state, name) {
        state.name = name;
    },
    setLink(state, link) {
        state.link = link;
    },
    setFormData(state, data) {
        state.name = data.name ?? state.name;
        state.phone = data.phone ?? state.phone;
        state.branchName = data.branchName ?? state.branchName;
        state.office_id = data.office_id ?? state.office_id;
        state.date = data.date ?? state.date;
        state.consultations_date_limit = data.consultations_date_limit ?? state.consultations_date_limit;
        state.timeStart = data.timeStart ?? state.timeStart;
        state.timeEnd = data.timeEnd ?? state.timeEnd;
        state.link = data.link ?? state.link;
    }
}
export const state = {
    times: [],
    branches: [],
    branch: {},
    contact_hash: '',
    activeCons: {},
    personal: {},
    link: '',
    phone: '',
    name: '',
    branchName: '',
    date: '',
    time: '',
    office_id: '',
    consultations_date_limit: 5,
    timeStart: '',
    timeEnd: ''
}
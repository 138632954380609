<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Bebas Neue';
  src: local('Bebas Neue'), url('~@/assets/fonts/Bebas/bebasneuebold.woff2') format('woff2');
  font-style: normal;
}

@font-face {
  font-family: 'Manrope Regular';
  src: local('Manrope Regular'), url("~@/assets/fonts/Manrope/Manroperegular.woff2") format('woff2');
  font-style: normal;
}

body {
  font-family: 'Manrope Regular';
  width: 100vw;
  width: 100dvw;
  font-size: 14px;
  overflow-x: hidden;
}

#app h1, h2{
  font-family: 'Manrope Regular';
}

@import "src/assets/style/app";
</style>
